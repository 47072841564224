#root{
  min-height: 100vh;
  min-width: fit-content;
}

.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  height: 100vh;
  background-color: #282c34;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header a img{
  padding: 10px;
  width: 25%;
}

.App-link {
  color: #61dafb;
}

svg[state="NEW"]{
  fill: smokewhite;
}

svg[state="INPROCESS"]{
  fill: rgb(252, 252, 170);
}

svg[state="APPROVED"]{
  fill: rgb(153, 231, 153);
}

svg[state="DONE"]{
  fill: rgb(105, 105, 247);
}

svg[state="OVERDUE"]{
  fill: rgb(252, 138, 138);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

